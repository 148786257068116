import { defineStore } from 'pinia';

const storage = defineStore({
  id: 'storage',
  state: () => ({
    brandInfo:{}
  }),
  getters: {},
  actions: {
    setBrandInfo(row) {
      this.brandInfo = row
    },
  },
  persist: {
		enabled: true,
		strategies: [{
			key: 'storage-store',
		},],
	},
});

export default storage;


