import App from './App';
import { createSSRApp } from 'vue';
import { setupPinia } from './sheep/store';
import {projectConfig} from "@/sheep/projectConfig/projectConfig";
import YSF from "@neysf/qiyu-web-sdk";
import CusService from "./components/cusService/index.vue";
import PhysicalGood from "./components/physicalGood/index.vue";
import uView from "uview-ui";

import Vant from 'vant';
import 'vant/lib/index.css';



YSF.init("17ece7fe18fc0c6a42c7d0d280cad04b", {
  subdomain: 1 ,
  sessionInvite: true, // sessionInvite表示是否开启会话邀请设置
  hidden: true // hidden表示是否隐藏访客端默认入口
});

export function createApp() {

  const app = createSSRApp(App);
  app.component('CusService', CusService)
  app.component('PhysicalGood', PhysicalGood)
  app.use(Vant);
  app.config.globalProperties.$projectConfig = projectConfig;
  setupPinia(app);

  return {
    app,
  };
}
