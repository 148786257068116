import request from '@/sheep/request';

const CartApi = {
  addCart: (data) => {
    return request({
      url: '/trade/cart/add',
      method: 'POST',
      data: data,
      custom: {
        showSuccess: true,
        successMsg: '已添加到购物车~',
        auth:true,
      }
    });
  },
  addCombineCart(data){
    return request({
      url: '/trade/cart/add-bundle',
      method: 'POST',
      data: data,
      custom: {
        showSuccess: true,  
        successMsg: '已添加到购物车~',
        auth:true,
      }
    });
  },
  updateCartCount: (data) => {
    return request({
      url: '/trade/cart/update-count',
      method: 'PUT',
      data: data
    });
  },
  updateCartCountBundle: (data) => {
    return request({
      url: '/trade/cart/update-count-bundle',
      method: 'PUT',
      data: data
    });
  },
  updateCartSelected: (data) => {
    return request({
      url: '/trade/cart/update-selected',
      method: 'PUT',
      data: data
    });
  },
  updateCartSelectedBundle: (data) => {
    return request({
      url: '/trade/cart/update-selected-bundle',
      method: 'PUT',
      data: data
    });
  },
  deleteCart: (ids) => {
    return request({
      url: '/trade/cart/delete',
      method: 'DELETE',
      params: {
        ids
      }
    });
  },
  deleteCartBundle: (spuIds,activityId) => {
    console.log(JSON.stringify(spuIds))
    return request({
      url: '/trade/cart/delete-bundle',
      method: 'DELETE', 
      params: {
        spuIds:spuIds,
        activityId:activityId
      }
    });
  },
  getCartList: (params) => {
    return request({
      url: '/trade/cart/list',
      method: 'GET',
      params,
      custom: {
        showLoading: false,
        auth: true,
      },
    });
  },
};

export default CartApi;