import request from '@/sheep/request';

const couponsApi = {
  // 判断是否绑卡
  checkCoupons() {
    return request({
      url: 'pay/coupons-user/check',
      method: 'GET',
    });
  },
 // 判断是否存在待签收卡
 checkCheckSign() {
  return request({
    url: 'pay/coupons-user/checkSign',
    method: 'POST',
  });
},
  // 解除绑定的卡券
  removeCoupon(entityId) {
    return request({
      url: `pay/coupons-user/unbind?entityId=${entityId}`,
      method: 'GET',
    });
  },

  // 分页获取用户卡券列表
  getCouponsList(params) {
    return request({
      url: `pay/coupons-user/page`,
      method: 'GET',
      params
    });
  },
  // 根据卡号获取卡详情
  getCouponsDetail(data) {
    return request({
      url: 'pay/coupons-user/get_detail',
      method: 'GET',
      data: data,
    });
  },
  // 绑卡
  createCoupons(data) {
    return request({
      url: 'pay/coupons-user/create',
      method: 'POST',
      data: data,
    });
  },

  //  查询是否自选类目卡 
  getOptionCard(data) {
    return request({
      url: '/pay/coupons-user/optional-category',
      method: 'POST',
      data: data,
    });
  },   

  // 获得用户可参与的卡券活动
  getUserActivity(data) {
    return request({
      url: '/pay/card-activity/get-user-activity',
      method: 'GET',
      data: data,
    });
  },

  //  根据销售单获取活动详情 
  getActivityBySeller(data) {
    return request({
      url: 'pay/card-activity/get-sellerOrder-activity',
      method: 'GET',
      data: data,
    });
  },

  // 验证绑卡答案
  checkAnswer(data) {
    return request({
      url: 'pay/coupons-user/verify-bind-card-answer',
      method: 'POST',
      data: data,
    });
  },
};

export default couponsApi;
