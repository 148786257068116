import request from '@/sheep/request';

const OrderApi = {
  // 计算订单信息
  settlementOrder: (data) => {
    const data2 = {
      ...data,
    };
    // 移除多余字段
    if (!(data.couponId > 0)) {
      delete data2.couponId;
    }
    if (!(data.addressId > 0)) {
      delete data2.addressId;
    }
    if (!(data.combinationActivityId) > 0) {
      delete data2.combinationActivityId;
    }
    if (!(data.combinationHeadId > 0)) {
      delete data2.combinationHeadId;
    }
    if (!(data.seckillActivityId > 0)) {
      delete data2.seckillActivityId;
    }
    // 解决 SpringMVC 接受 List<Item> 参数的问题
    delete data2.items;
    for (let i = 0; i < data.items.length; i++) {
      data2[encodeURIComponent('items[' + i + '' + '].skuId')] = data.items[i].skuId + '';
      data2[encodeURIComponent('items[' + i + '' + '].count')] = data.items[i].count + '';
      data2[encodeURIComponent('items[' + i + '' + '].minNums')] = data.items[i].minNums + '';
      if (data.items[i].cartId) {
        data2[encodeURIComponent('items[' + i + '' + '].cartId')] = data.items[i].cartId + '';
      }
      // data2[encodeURIComponent('items[' + i + '' + '].supplierDeliveryPrice')] = 300 + '';
      if (data.items[i].itemExtras && data.items[i].itemExtras.supplierDeliveryPrice) {
        data2[encodeURIComponent('items[' + i + '' + '].supplierDeliveryPrice')] = data.items[i].itemExtras.supplierDeliveryPrice + '';
      }
      if(data.items[i].groupSpuId){
        data2[encodeURIComponent('items[' + i + '' + '].groupSpuId')] = data.items[i].groupSpuId + '';
      }
      if(data.items[i].groupCount){
        data2[encodeURIComponent('items[' + i + '' + '].groupCount')] = data.items[i].groupCount + '';
      }
    }
    // console.log(data,'data')
    const queryString = Object.keys(data2)
      .map((key) => key + '=' + data2[key])
      .join('&');

    return request({
      url: `/trade/order/settlement?${queryString}`,
      method: 'GET',
      custom: {
        showError: true,
        showLoading: true,
      },
    });
  },
  // 创建订单
  createOrder: (data) => {
    return request({
      url: `/trade/order/create`,
      method: 'POST',
      data,
    });
  },
  // 提交充值
  submitCharge: (data) => {
    return request({
      url: `/trade/supplier-order/submit-charge`,
      method: 'POST',
      data,
    });
  },
  // 查询快递新
  NewExpressTrack: (id) => {
    return request({
      // url: `/trade/order/get-express-item-track-list?id=` + id,
      url: `/trade/order/get-express-item-track-list-02?id=` + id,
      method: 'GET',
      data: {},
    });
  },
  // 校验订单
  orderCheck: (data) => {
    return request({
      url: `/trade/order/orderCheck`,
      method: 'POST',
      data,
    });
  },
  // 获得订单
  getOrder: (params) => {
    return request({
      url: `/trade/order/get-detail`,
      method: 'GET',
      params,
      custom: {
        showLoading: false,
      },
    });
  },
  // 获得京东订单
  getJDOrder: (id) => {
    return request({
        url: `/jd/order/get-detail`,
        method: 'GET',
        params: {
          id,
        },
        custom: {
          showLoading: false,
        },
      });
    },
  // 取消京东订单
  cancelJDOrder: (data) => {
    return request({
      url: `/jd/order/cancel`,
      method: 'post',
      data
    });
  },
  // 取消京东订单
  cancelTMOrder: (data) => {
    return request({
      url: `/tmall/order/cancel`,
      method: 'post',
      data
    });
  },
  // 订单列表
  getOrderPage: (params) => {
    return request({
      url: '/trade/order/page',
      method: 'GET',
      params,
      custom: {
        showLoading: false,
      },
    });
  },
  // 获得美团订单列表 
  getMeituanPage: (params) => {
    return request({
      url: '/meituan/order/page',
      method: 'GET',
      params,
      custom: {
        showLoading: false,
      },
    });
  },
 
   //  获取京东订单列表
   getJingDongPage: (params) => {
    return request({
      url: '/jd/order/page',
      method: 'GET',
      params,
      custom: {
        showLoading: false,
      },
    });
  },
   // 订单列表全部类型
   getOrderAllPage: (params) => {
    return request({
      url: '/trade/order/page-all',
      method: 'GET',
      params,
      custom: {
        showLoading: false,
      },
    });
  },
  // 确认收货
  receiveOrder: (id) => {
    return request({
      url: `/trade/order/receive`,
      method: 'PUT',
      params: {
        id,
      },
    });
  },
  // 取消订单
  cancelOrder: (id) => {
    return request({
      url: `/trade/order/cancel`,
      method: 'DELETE',
      params: {
        id,
      },
    });
  },
  // 删除订单
  deleteOrder: (id) => {
    return request({
      url: `/trade/order/delete`,
      method: 'DELETE',
      params: {
        id,
      },
    });
  },
  // 获得交易订单的物流轨迹
  getOrderNewEList: (id) => {
    return request({
      url: `/trade/order/get-express-track-list`,
      method: 'GET',
      params: {
        id,
      },
    });
  },
  // 获得交易订单数量
  getOrderCount: () => {
    return request({
      url: '/trade/order/get-count',
      method: 'GET',
      custom: {
        showLoading: false,
        auth: true,
      },
    });
  },
  // 创建单个评论
  createOrderItemComment: (data) => {
    return request({
      url: `/trade/order/item/create-comment`,
      method: 'POST',
      data,
    });
  },

  // 获得自营订单卡密信息 
  getSelfOrderInfo: (id) => {
    return request({
      url: `/trade/order/get-product-card-self`,
      method: 'GET',
      params: {
        id,
      },
    });
  },
  // 获得第三方订单卡密信息 
  getThirdOrderInfo: (id) => {
    return request({
      url: `/trade/order/get-product-card-third`,
      method: 'GET',
      params: {
        id,
      },
    });
  },
  
  // 逻辑删除订单
  userDelete: (params) => {
    return request({
      url: `/trade/order/userDelete`,
      method: 'DELETE',
      params,
    });
  },

};

export default OrderApi;
