import request from '@/sheep/request';

const SystemApi = {
  // 获取字典指定键值
  getValueByDicKey:(key)=>{
    return request({
      url: 'system/dict-data/type',
      method: 'GET',
      data:{
        type:key
      }
    })
  },

  // 埋点
  burialPoint:(data)=>{
    return request({
      url: 'statistics/burialPoint/create',
      method: 'post',
      data,
      custom: {
        showLoading: false,
        showError: 0
      },
    })
  }
};


export default SystemApi;
