import request from '@/sheep/request';

const PayOrderApi = {

  // 获取云福豆余额
  getBalance: (data) => {
    return request({
      url: '/pay/bean/getBalance',
      method: 'POST',
      data
    });
  },
  // 获取云福豆钱包
  getMyCardToBean: (params) => {
    return request({
      url: '/pay/bean/my-cardToBean',
      method: 'GET',
      params
    });
  },
  // 获取云福豆流水
  getBeanTransaction: (params) => {
    const queryString = Object.keys(params)
      .map((key) => encodeURIComponent(key) + '=' + params[key])
      .join('&');
    return request({
      url: `/pay/bean-transaction/page-bean-transaction?${queryString}`,
      method: 'GET',
      // params
    });
  },
  // 获取云福豆余额
  getCardToBeanMoney: (params) => {
    return request({
      url: '/pay/bean/get/cardToBeanMoney',
      method: 'GET',
      params
    });
  },
  // 获得支付订单
  getSeckillActivityId: (id) => {
    return request({
      url: '/trade/order/get-detail',
      method: 'GET',
      params: { id }
    });
  },
  // 获得支付订单
  getOrder: (id) => {
    return request({
      url: '/pay/order/get',
      method: 'GET',
      params: { id }
    });
  },
  uploadFile: (data) => {
    return request({
      url: '/infra/file/upload',
      method: 'POST',
      data
    });
  },

  // 提交支付订单
  submitOrder: (data) => {
    return request({
      url: '/pay/order/submit',
      method: 'POST',
      data
    });
  },
  // 卡券列表
  couponsUserPage: (params) => {
    return request({
      url: '/pay/coupons-user/page',
      method: 'GET',
      params
    });
  },
  couponsDetail: (params) => {
    console.log(params)
    return request({
      url: '/pay/coupons-user/get_card_detail',
      method: 'GET',
      params
    });
  },

  // 可用卡券列表
  couponsUserPageNew: (params) => {
    return request({
      url: '/pay/coupons-user/can-use-page',
      method: 'GET',
      params
    });
  },
  // 卡券签收
  couponsUsertSign: (data) => {
    return request({
      url: '/pay/coupons-user/sign',
      method: 'POST',
      data
    });
  },
  // 次卡转余额
  cardAmtToWallet: (params) => {
    return request({
      url: '/pay/card-amt/cardAmtToWallet',
      method: 'GET',
      params
    });
  },
  // 转余额
  cardToBean: (params) => {
    return request({
      url: '/pay/bean/cardToBean',
      method: 'GET',
      params
    });
  },
  // 是否有可以卡转余额的卡
  isCardQuotaConvertibleToBalance: (params) => {
    return request({
      url: '/pay/bean/isCardToBean',
      method: 'GET',
      params
    });
  },

  // 次卡转余额即将到期余额
  getCardAmtToWallet: (params) => {
    return request({
      url: '/pay/card-amt/cardAmtToWallet/expire',
      method: 'GET',
      params
    });
  },
  
  // 获取会员本次权益 
  getRechargeCurrent: (params) => {
    return request({
      url: '/pay/recharge-equity/get/current',
      method: 'GET',
      params,
    });
  }
};


export default PayOrderApi;
